/** @jsx jsx */
//import React, { useState } from "react";
import styled from "@emotion/styled";
import { /*css,*/ jsx } from "@emotion/react";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

const DebtSecurity = ({ match }) => {
  const tableHeader = (
    <thead>
      <tr>
        <td>Date</td>
        <td>Price</td>
        <td>Yield</td>
        <td className="text-nowrap">Z-Spread</td>
        <td>EBP</td>
      </tr>
    </thead>
  );

  document.title = match.params.cusip;

  const debtInstrumentsByCompany = [
    {
      CompanyName: "Ford",
      CompanyTicker: "F",
      ExchangeTradedDebtSecurities: [
        { Ticker: "F-A", Cusip: "345370845" },
        { Ticker: "F-B", Cusip: "345370852" },
        { Ticker: "F-C", Cusip: "345370837" },
      ],
    },
    {
      CompanyName: "United States Cellular",
      CompanyTicker: "USM",
      ExchangeTradedDebtSecurities: [
        { Ticker: "UZD", Cusip: "911684702" },
        { Ticker: "UZA", Cusip: "911684405" },
        { Ticker: "UZB", Cusip: "911684504" },
        { Ticker: "UZC", Cusip: "911684603" },
        { Ticker: "UZV*", Cusip: "911684306" },
        { Ticker: "UZV*", Cusip: "911684306" },
        { Ticker: "UZG*", Cusip: "911684207" },
        { Ticker: "UZV*", Cusip: "911684306" },
      ],
    },
    {
      CompanyName: "Telephone and Data Systems",
      CompanyTicker: "TDS",
      ExchangeTradedDebtSecurities: [
        { Ticker: "TDA", Cusip: "879433795" },
        { Ticker: "TDI", Cusip: "879433852" },
        { Ticker: "TDE", Cusip: "879433845" },
        { Ticker: "TDJ", Cusip: "879433837" },
        { Ticker: "TDA*", Cusip: "879433878" },
      ],
    },
    {
      CompanyName: "Qurate Retail Group",
      CompanyTicker: "QRTEA",
      ExchangeTradedDebtSecurities: [
        { Ticker: "QVCC", Cusip: "747262400" },
        { Ticker: "QVCD", Cusip: "747262301" },
      ],
    },
  ];
  const [priceData, setPriceData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [match.params.cusip]);

  const chartOptions = {
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const getData = async () => {
    const apiRes = await fetch(
      `https://api.research.lawson.net.au/api/bond-prices?bond_id=${match.params.cusip}`
    );
    const resJSON = await apiRes.json();
    setChartData();
    setPriceData(resJSON);
    setIsLoading(false);
    //return resJSON;
  };
  if (isLoading) {
    return (
      <Row>
        <Col lg="2">
          <ul>
            {debtInstrumentsByCompany.map((c) => (
              <>
                <li key={c.CompanyTicker}>
                  {c.CompanyName} ({c.CompanyTicker})
                </li>
                <ul>
                  {c.ExchangeTradedDebtSecurities.map((d) => (
                    <li key={d.Cusip}>
                      <Link to={`/DebtSecurity/${d.Cusip}`}>
                        {d.Ticker} ({d.Cusip})
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ))}
          </ul>
        </Col>
        <Col>
          <h1>{match.params.cusip}</h1>
          <Table variant="dark" size="sm">
            {tableHeader}
            <tbody>
              <tr>
                <td colSpan="5">
                  Loading ...{" "}
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  } else if (priceData.length === 0) {
    return (
      <Row>
        <Col lg="2">
          <ul>
            {debtInstrumentsByCompany.map((c) => (
              <>
                <li key={c.CompanyTicker}>
                  {c.CompanyName} ({c.CompanyTicker})
                </li>
                <ul>
                  {c.ExchangeTradedDebtSecurities.map((d) => (
                    <li key={d.Cusip}>
                      <Link to={`/DebtSecurity/${d.Cusip}`}>
                        {d.Ticker} ({d.Cusip})
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ))}
          </ul>
        </Col>
        <Col>
          <h1>{match.params.cusip}</h1>
          <Table variant="dark" size="sm">
            {tableHeader}
            <tbody>
              <tr>
                <td colSpan="5">No price data</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col lg="2">
          <ul>
            {debtInstrumentsByCompany.map((c) => (
              <>
                <li key={c.CompanyTicker}>
                  {c.CompanyName} ({c.CompanyTicker})
                </li>
                <ul>
                  {c.ExchangeTradedDebtSecurities.map((d) => (
                    <li key={d.Cusip}>
                      <Link to={`/DebtSecurity/${d.Cusip}`}>
                        {d.Ticker} ({d.Cusip})
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ))}
          </ul>
        </Col>
        <Col lg="3">
          <h1>{match.params.cusip}</h1>

          <Table variant="dark" size="sm">
            {tableHeader}
            <tbody>
              {priceData.map((p) => (
                <tr key={p.price_date}>
                  <td className="text-nowrap">{p.price_date.slice(0, 10)}</td>
                  <td>{p.price.toFixed(2)}</td>
                  <td>{p.yield.toFixed(5)}</td>
                  <td>{p.zSpread.toFixed(5)}</td>
                  <td>{p.EBP.toFixed(10)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col lg="5">
          <Line
            data={{
              labels: priceData.map((x) => x.price_date.slice(0, 10)),
              datasets: [
                {
                  label: "EBP",
                  data: priceData.map((x) => x.EBP),
                  fill: false,
                  borderColor: "rgba(0, 0,0, 1)",
                },
              ],
            }}
            height={300}
            width={700}
            options={chartOptions}
          />
        </Col>
      </Row>
    );
  }
};

export default DebtSecurity;
