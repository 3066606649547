/** @jsx jsx */
//import React, { useState } from "react";
import styled from "@emotion/styled";
import { /*css,*/ jsx } from "@emotion/react";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

const EBP = ({ match }) => {
  const tableHeader = (
    <thead>
      <tr>
        <td>Date</td>
        <td>Price</td>
        <td>Yield</td>
        <td className="text-nowrap">Z-Spread</td>
        <td>EBP</td>
      </tr>
    </thead>
  );

  document.title = match.params.cusip;

  const [EBPData, setEBPData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [match.params.cusip]);

  const getData = async () => {
    const apiRes = await fetch(
      `https://api.research.lawson.net.au/api/excess-bond-premium?cusip=${match.params.cusip}`
    );
    const resJSON = await apiRes.json();
    setEBPData(resJSON);
    setIsLoading(false);
    //return resJSON;
  };
  if (isLoading) {
    return (
      <Row>
        <Col>
          <h1>{match.params.cusip} - Excess Bond Premium</h1>
          <Table variant="dark" size="sm">
            <tbody>
              <tr>
                <td colSpan="5">
                  Loading ...{" "}
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  } else if (EBPData.bondPrices.length === 0) {
    return (
      <Row>
        <h1>{match.params.cusip} - Excess Bond Premium</h1>
        <Col>
          <h1>{match.params.cusip}</h1>
          <Table variant="dark" size="sm">
            <tbody>
              <tr>
                <td colSpan="5">No data</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col>
          <h1>{match.params.cusip} - Excess Bond Premium</h1>

          <h2>EBP: {EBPData.bondPrices[EBPData.bondPrices.length - 1].EBP}</h2>
          <Table variant="dark" size="sm">
            <tbody>
              <tr>
                {Object.keys(EBPData.bondPrices[0]).map((k) => (
                  <td>{k}</td>
                ))}
              </tr>
              {EBPData.bondPrices.map((row) => (
                <tr>
                  {Object.keys(row).map((k) => (
                    <td className="text-nowrap">{row[k]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
};

export default EBP;
