/** @jsx jsx */
//import React, { useState } from "react";
import styled from "@emotion/styled";
import { /*css,*/ jsx } from "@emotion/react";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

const CompanyFundamentals = ({ match }) => {
  const [companyData, setCompanyData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Use Effect to caputure when company ticker changes
  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  document.title = match.params.ticker;

  const getData = async () => {
    const apiRes = await fetch(
      `https://api.research.lawson.net.au/api/company-fundamentals-comprehensive?company_ticker=${match.params.ticker}`
    );
    const resJSON = await apiRes.json();
    setCompanyData(resJSON);
    setIsLoading(false);
    //return resJSON;
  };

  const fundmentalRow = (keyId, isDate = false, isHighlighted = false) => {
    return (
      <tr className={isHighlighted ? "table-active" : ""}>
        <td className="text-nowrap">
          {labels[keyId.toUpperCase()].fullLabel.split(" -- ")[1]}
          &nbsp;&nbsp;
          {"isCalc" in labels[keyId.toUpperCase()] ? (
            <Badge variant="info">*</Badge>
          ) : (
            ""
          )}
        </td>
        {companyData.fundamentalData.data[
          companyData.fundamentalData.index.indexOf(keyId)
        ].map((item) => (
          <td className={"text-right text-nowrap"}>
            {isDate
              ? item.substring(0, 10)
              : Number(item).toLocaleString("en-US")}
          </td>
        ))}
      </tr>
    );
  };

  if (isLoading === false) {
    return (
      <Container fluid className="bg-dark text-white">
        <h1>
          {match.params.ticker} - {companyData.referenceData["conml"]}
        </h1>
        <h2>Financial Statements Data</h2>
        <Table variant="dark" size="sm">
          <tbody>
            {fundmentalRow("datadate", true)}
            {fundmentalRow("prccq")}
            {fundmentalRow("cshoq")}
            {fundmentalRow("mkvaltq")}
            {fundmentalRow("fqtr")}
            <tr>
              <td>&nbsp;</td>
            </tr>{" "}
            {fundmentalRow("revtq")}
            {fundmentalRow("revt4q")}
            {fundmentalRow("revt4q_per_share")}
            {fundmentalRow("revt4q_by_mkvaltq", false, true)}
            {fundmentalRow("cogsq")}
            {fundmentalRow("grossprofitq")}
            {fundmentalRow("grossmarginq")}
            {fundmentalRow("oibdpq")}
            {fundmentalRow("piq")}
            {fundmentalRow("niq")}
            {fundmentalRow("ni_t4q")}
            <tr>
              <td>&nbsp;</td>
            </tr>
            {fundmentalRow("cheq")}
            {fundmentalRow("cheq_by_mkvaltq")}
            {fundmentalRow("actq")}
            {fundmentalRow("ancq")}
            {fundmentalRow("atq", false, true)}
            {fundmentalRow("dlttq")}
            {fundmentalRow("ltq", false, true)}
            {fundmentalRow("teqq")}
            {fundmentalRow("teqq_by_mkvaltq", false, true)}
            <tr>
              <td>&nbsp;</td>
            </tr>
            {fundmentalRow("oancfq")}
            {fundmentalRow("oancfq_t4q")}
            {fundmentalRow("oancfy")}
            {fundmentalRow("fincfy")}
            {fundmentalRow("ivncfy")}
          </tbody>
        </Table>
        <h2>Company Reference Data</h2>
        <p>
          {companyData.referenceData["gsector_gicdesc"]}
          {" | "}
          {companyData.referenceData["ggroup_gicdesc"]}
          {" | "}
          {companyData.referenceData["gind_gicdesc"]}
          {" | "}
          {companyData.referenceData["gsubind_gicdesc"]}
        </p>
        <p>{companyData.referenceData["busdescl"]}</p>
        <p>{companyData.referenceData["weburl"]}</p>
        <h2>Bond Data</h2>
        <Table variant="dark" size="sm">
          <thead>
            <tr>
              <td>complete_cusip</td>
              <td>maturity</td>
              <td>amount_outstanding</td>
              {companyData.bonds.columns.map((colHeader) => (
                <td>{colHeader}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {companyData.bonds.data.map((record) => (
              <tr>
                <td>
                  <Link
                    to={`/EBP/${
                      record[
                        companyData.bonds.columns.indexOf("complete_cusip")
                      ]
                    }`}
                  >
                    {
                      record[
                        companyData.bonds.columns.indexOf("complete_cusip")
                      ]
                    }
                  </Link>
                </td>
                <td className="text-nowrap">
                  {record[
                    companyData.bonds.columns.indexOf("maturity")
                  ].substring(0, 10)}
                </td>
                <td>
                  {
                    record[
                      companyData.bonds.columns.indexOf("amount_outstanding")
                    ]
                  }
                </td>
                {record.map((col) => (
                  <td>{col}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
        <h2>ALL Financial Statements Data (Raw, Unsorted)</h2>
        <p>&nbsp;</p>
        <Table variant="dark" size="sm">
          <tbody>
            {companyData.fundamentalData.index.map((row) => (
              <tr>
                <td>
                  {row.toUpperCase() in labels
                    ? labels[row.toUpperCase()].fullLabel
                    : "MISSING"}
                </td>
                <td>{row}</td>
                {companyData.fundamentalData.data[
                  companyData.fundamentalData.index.indexOf(row)
                ].map((col) => (
                  <td>{col}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    );
  } else {
    return (
      <Container
        fluid
        className="bg-dark text-white"
        className="bg-dark text-white"
      >
        <div style={{ width: "500px", borderwidth: "3px" }}>
          <h1 style={{ display: "inline-block", paddingRight: "10px" }}>
            {match.params.ticker}
          </h1>
          <Spinner
            animation="border"
            role="status"
            style={{ display: "inline-block" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }
};

const labels = {
  REVT4Q: { fullLabel: "REVT4Q -- Revenue T4Q - Total", isCalc: true },
  REVT4Q_PER_SHARE: {
    fullLabel: "REVT4Q_PER_SHARE -- Revenue T4Q / Share",
    isCalc: true,
  },
  REVT4Q_BY_MKVALTQ: {
    fullLabel: "REVT4Q_BY_MKVALTQ -- Revenue T4Q / Market Cap",
    isCalc: true,
  },
  OANCFQ: {
    fullLabel: "OANCFQ_T4Q -- Operating Activities - Net Cash Flow QTR",
    isCalc: true,
  },
  OANCFQ_T4Q: {
    fullLabel: "OANCFQ_T4Q -- Operating Activities - Net Cash Flow T4Q",
    isCalc: true,
  },
  NI_T4Q: {
    fullLabel: "NI_T4Q -- Net Income (Loss) T4Q",
    isCalc: true,
  },
  TEQQ_BY_MKVALTQ: {
    fullLabel: "TEQQ_BY_MKVALTQ -- Stockholders Equity / Market Cap",
    isCalc: true,
  },
  CHEQ_BY_MKVALTQ: {
    fullLabel:
      "CHEQ_BY_MKVALTQ -- Cash and Short-Term Investments / Market Cap",
    isCalc: true,
  },
  GROSSMARGINQ: {
    fullLabel: "GROSSMARGINQ -- Gross Margin",
    isCalc: true,
  },
  GROSSPROFITQ: {
    fullLabel: "GROSSPROFITQ -- Gross Profit",
    isCalc: true,
  },
  DATADATE: { fullLabel: "DATADATE -- Date" },
  ACCHGQ: { fullLabel: "ACCHGQ -- Accounting Changes - Cumulative Effect" },
  ACCHGY: { fullLabel: "ACCHGY -- Accounting Changes - Cumulative Effect" },
  ACCTCHGQ: { fullLabel: "ACCTCHGQ -- Adoption of Accounting Changes" },
  ACCTSTDQ: { fullLabel: "ACCTSTDQ -- Accounting Standard" },
  ACOMINCQ: {
    fullLabel: "ACOMINCQ -- Accumulated Other Comprehensive Income (Loss)",
  },
  ACOQ: { fullLabel: "ACOQ -- Current Assets - Other - Total" },
  ACTQ: { fullLabel: "ACTQ -- Current Assets - Total" },
  ADD1: { fullLabel: "ADD1 -- Address Line 1" },
  ADD2: { fullLabel: "ADD2 -- Address Line 2" },
  ADD3: { fullLabel: "ADD3 -- Address Line 3" },
  ADD4: { fullLabel: "ADD4 -- Address Line 4" },
  ADDZIP: { fullLabel: "ADDZIP -- Postal Code" },
  ADJEX: { fullLabel: "ADJEX -- Cumulative Adjustment Factor by Ex-Date" },
  ADRRQ: { fullLabel: "ADRRQ -- ADR Ratio" },
  AFUDCCY: {
    fullLabel:
      "AFUDCCY -- Allowance for Funds Used During Construction (Cash Flow)",
  },
  AFUDCIY: {
    fullLabel:
      "AFUDCIY -- Allowance for Funds Used During Construction (Investing) (Cash Flow)",
  },
  AJEXQ: {
    fullLabel: "AJEXQ -- Adjustment Factor (Company) - Cumulative by Ex-Date",
  },
  AJPQ: {
    fullLabel: "AJPQ -- Adjustment Factor (Company) - Cumulative byPay-Date",
  },
  ALTOQ: { fullLabel: "ALTOQ -- Other Long-term Assets" },
  AMCY: { fullLabel: "AMCY -- Amortization (Cash Flow)" },
  ANCQ: { fullLabel: "ANCQ -- Non-Current Assets - Total" },
  ANOQ: { fullLabel: "ANOQ -- Assets Netting & Other Adjustments" },
  AOCIDERGLQ: {
    fullLabel:
      "AOCIDERGLQ -- Accum Other Comp Inc - Derivatives Unrealized Gain/Loss",
  },
  AOCIOTHERQ: {
    fullLabel: "AOCIOTHERQ -- Accum Other Comp Inc - Other Adjustments",
  },
  AOCIPENQ: {
    fullLabel: "AOCIPENQ -- Accum Other Comp Inc - Min Pension Liab Adj",
  },
  AOCISECGLQ: {
    fullLabel:
      "AOCISECGLQ -- Accum Other Comp Inc - Unreal G/L Ret Int in Sec Assets",
  },
  AOL2Q: { fullLabel: "AOL2Q -- Assets Level2 (Observable)" },
  AOLOCHY: {
    fullLabel: "AOLOCHY -- Assets and Liabilities - Other (Net Change)",
  },
  AOQ: { fullLabel: "AOQ -- Assets - Other - Total" },
  APALCHY: {
    fullLabel:
      "APALCHY -- Accounts Payable and Accrued Liabilities - Increase (Decrease)",
  },
  APDEDATEQ: { fullLabel: "APDEDATEQ -- Actual Period End date" },
  APQ: { fullLabel: "APQ -- Account Payable/Creditors - Trade" },
  AQAQ: { fullLabel: "AQAQ -- Acquisition/Merger After-Tax" },
  AQAY: { fullLabel: "AQAY -- Acquisition/Merger After-Tax" },
  AQCY: { fullLabel: "AQCY -- Acquisitions" },
  AQDQ: { fullLabel: "AQDQ -- Acquisition/Merger Diluted EPS Effect" },
  AQDY: { fullLabel: "AQDY -- Acquisition/Merger Diluted EPS Effect" },
  AQEPSQ: { fullLabel: "AQEPSQ -- Acquisition/Merger Basic EPS Effect" },
  AQEPSY: { fullLabel: "AQEPSY -- Acquisition/Merger Basic EPS Effect" },
  AQPL1Q: { fullLabel: "AQPL1Q -- Assets Level1 (Quoted Prices)" },
  AQPQ: { fullLabel: "AQPQ -- Acquisition/Merger Pretax" },
  AQPY: { fullLabel: "AQPY -- Acquisition/Merger Pretax" },
  ARCEDQ: { fullLabel: "ARCEDQ -- As Reported Core - Diluted EPS Effect" },
  ARCEDY: { fullLabel: "ARCEDY -- As Reported Core - Diluted EPS Effect" },
  ARCEEPSQ: { fullLabel: "ARCEEPSQ -- As Reported Core - Basic EPS Effect" },
  ARCEEPSY: { fullLabel: "ARCEEPSY -- As Reported Core - Basic EPS Effect" },
  ARCEQ: { fullLabel: "ARCEQ -- As Reported Core - After-tax" },
  ARCEY: { fullLabel: "ARCEY -- As Reported Core - After-tax" },
  ATQ: { fullLabel: "ATQ -- Assets - Total" },
  AUL3Q: { fullLabel: "AUL3Q -- Assets Level3 (Unobservable)" },
  BILLEXCEQ: {
    fullLabel: "BILLEXCEQ -- Billings in Excess of Cost & Earnings",
  },
  BSPRQ: { fullLabel: "BSPRQ -- Balance Sheet Presentation" },
  BUSDESC: { fullLabel: "BUSDESC -- S&P Business Description" },
  CAPR1Q: { fullLabel: "CAPR1Q -- Risk-Adjusted Capital Ratio - Tier 1" },
  CAPR2Q: { fullLabel: "CAPR2Q -- Risk-Adjusted Capital Ratio - Tier 2" },
  CAPR3Q: { fullLabel: "CAPR3Q -- Risk-Adjusted Capital Ratio - Combined" },
  CAPSFTQ: { fullLabel: "CAPSFTQ -- Capitalized Software" },
  CAPSQ: { fullLabel: "CAPSQ -- Capital Surplus/Share Premium Reserve" },
  CAPXY: { fullLabel: "CAPXY -- Capital Expenditures" },
  CDVCY: { fullLabel: "CDVCY -- Cash Dividends on Common Stock (Cash Flow)" },
  CEIEXBILLQ: {
    fullLabel: "CEIEXBILLQ -- Cost & Earnings in Excess of Billings",
  },
  CEQQ: { fullLabel: "CEQQ -- Common/Ordinary Equity - Total" },
  CHECHY: {
    fullLabel: "CHECHY -- Cash and Cash Equivalents - Increase (Decrease)",
  },
  CHEQ: { fullLabel: "CHEQ -- Cash and Short-Term Investments" },
  CHQ: { fullLabel: "CHQ -- Cash" },
  CIBEGNIQ: { fullLabel: "CIBEGNIQ -- Comp Inc - Beginning Net Income" },
  CIBEGNIY: { fullLabel: "CIBEGNIY -- Comp Inc - Beginning Net Income" },
  CICURRQ: { fullLabel: "CICURRQ -- Comp Inc - Currency Trans Adj" },
  CICURRY: { fullLabel: "CICURRY -- Comp Inc - Currency Trans Adj" },
  CIDERGLQ: { fullLabel: "CIDERGLQ -- Comp Inc - Derivative Gains/Losses" },
  CIDERGLY: { fullLabel: "CIDERGLY -- Comp Inc - Derivative Gains/Losses" },
  CIK: { fullLabel: "CIK Number" },
  CIMIIQ: {
    fullLabel: "CIMIIQ -- Comprehensive Income - Noncontrolling Interest",
  },
  CIMIIY: {
    fullLabel: "CIMIIY -- Comprehensive Income - Noncontrolling Interest",
  },
  CIOTHERQ: { fullLabel: "CIOTHERQ -- Comp Inc - Other Adj" },
  CIOTHERY: { fullLabel: "CIOTHERY -- Comp Inc - Other Adj" },
  CIPENQ: { fullLabel: "CIPENQ -- Comp Inc - Minimum Pension Adj" },
  CIPENY: { fullLabel: "CIPENY -- Comp Inc - Minimum Pension Adj" },
  CIQ: { fullLabel: "CIQ -- Comprehensive Income - Total" },
  CISECGLQ: { fullLabel: "CISECGLQ -- Comp Inc - Securities Gains/Losses" },
  CISECGLY: { fullLabel: "CISECGLY -- Comp Inc - Securities Gains/Losses" },
  CITOTALQ: { fullLabel: "CITOTALQ -- Comprehensive Income - Parent" },
  CITOTALY: { fullLabel: "CITOTALY -- Comprehensive Income - Parent" },
  CITY: { fullLabel: "CITY -- City" },
  CIY: { fullLabel: "CIY -- Comprehensive Income - Total" },
  COGSQ: { fullLabel: "COGSQ -- Cost of Goods Sold" },
  COGSY: { fullLabel: "COGSY -- Cost of Goods Sold" },
  COMPSTQ: { fullLabel: "COMPSTQ -- Comparability Status" },
  CONM: { fullLabel: "Company Name" },
  CONML: { fullLabel: "CONML -- Company Legal Name" },
  COUNTY: { fullLabel: "COUNTY -- County Code" },
  CSH12Q: {
    fullLabel:
      "CSH12Q -- Common Shares Used to Calculate Earnings Per Share - 12 Months Moving",
  },
  CSHFD12: {
    fullLabel:
      "CSHFD12 -- Common Shares Used to Calc Earnings Per Share - Fully Diluted - 12 Months M",
  },
  CSHFDQ: { fullLabel: "CSHFDQ -- Com Shares for Diluted EPS" },
  CSHFDY: { fullLabel: "CSHFDY -- Com Shares for Diluted EPS" },
  CSHIQ: { fullLabel: "CSHIQ -- Common Shares Issued" },
  CSHOPQ: { fullLabel: "CSHOPQ -- Total Shares Repurchased - Quarter" },
  CSHOQ: { fullLabel: "CSHOQ -- Common Shares Outstanding" },
  CSHPRQ: {
    fullLabel:
      "CSHPRQ -- Common Shares Used to Calculate Earnings Per Share - Basic",
  },
  CSHPRY: {
    fullLabel:
      "CSHPRY -- Common Shares Used to Calculate Earnings Per Share - Basic",
  },
  CSHTRQ: { fullLabel: "CSHTRQ -- Common Shares Traded - Quarter" },
  CSTKCVQ: { fullLabel: "CSTKCVQ -- Carrying Value" },
  CSTKEQ: { fullLabel: "CSTKEQ -- Common Stock Equivalents - Dollar Savings" },
  CSTKEY: { fullLabel: "CSTKEY -- Common Stock Equivalents - Dollar Savings" },
  CSTKQ: { fullLabel: "CSTKQ -- Common/Ordinary Stock (Capital)" },
  CURNCDQ: { fullLabel: "CURNCDQ -- Native Currency Code" },
  CURRTRQ: { fullLabel: "CURRTRQ -- Currency Translation Rate" },
  CURUSCNQ: { fullLabel: "CURUSCNQ -- US Canadian Translation Rate - Interim" },
  CUSIP: { fullLabel: "CUSIP" },
  DATACQTR: { fullLabel: "DATACQTR -- Calendar Data Year and Quarter" },
  DATAFQTR: { fullLabel: "DATAFQTR -- Fiscal Data Year and Quarter" },
  DCOMQ: { fullLabel: "DCOMQ -- Deferred Compensation" },
  DD1Q: { fullLabel: "DD1Q -- Long-Term Debt Due in One Year" },
  DEPCY: { fullLabel: "DEPCY -- Depreciation and Depletion (Cash Flow)" },
  DERACQ: { fullLabel: "DERACQ -- Derivative Assets - Current" },
  DERALTQ: { fullLabel: "DERALTQ -- Derivative Assets Long-Term" },
  DERHEDGLQ: {
    fullLabel: "DERHEDGLQ -- Gains/Losses on Derivatives and Hedging",
  },
  DERHEDGLY: {
    fullLabel: "DERHEDGLY -- Gains/Losses on Derivatives and Hedging",
  },
  DERLCQ: { fullLabel: "DERLCQ -- Derivative Liabilities- Current" },
  DERLLTQ: { fullLabel: "DERLLTQ -- Derivative Liabilities Long-Term" },
  DILADQ: { fullLabel: "DILADQ -- Dilution Adjustment" },
  DILADY: { fullLabel: "DILADY -- Dilution Adjustment" },
  DILAVQ: {
    fullLabel: "DILAVQ -- Dilution Available - Excluding Extraordinary Items",
  },
  DILAVY: {
    fullLabel: "DILAVY -- Dilution Available - Excluding Extraordinary Items",
  },
  DLCCHY: { fullLabel: "DLCCHY -- Changes in Current Debt" },
  DLCQ: { fullLabel: "DLCQ -- Debt in Current Liabilities" },
  DLDTE: { fullLabel: "DLDTE -- Research Company Deletion Date" },
  DLRSN: { fullLabel: "DLRSN -- Research Co Reason for Deletion" },
  DLTISY: { fullLabel: "DLTISY -- Long-Term Debt - Issuance" },
  DLTRY: { fullLabel: "DLTRY -- Long-Term Debt - Reduction" },
  DLTTQ: { fullLabel: "DLTTQ -- Long-Term Debt - Total" },
  DOQ: { fullLabel: "DOQ -- Discontinued Operations" },
  DOY: { fullLabel: "DOY -- Discontinued Operations" },
  DPACREQ: { fullLabel: "DPACREQ -- Accumulated Depreciation of RE Property" },
  DPACTQ: {
    fullLabel:
      "DPACTQ -- Depreciation, Depletion and Amortization (Accumulated)",
  },
  DPCY: {
    fullLabel:
      "DPCY -- Depreciation and Amortization - Statement of Cash Flows",
  },
  DPQ: { fullLabel: "DPQ -- Depreciation and Amortization - Total" },
  DPRETQ: { fullLabel: "DPRETQ -- Depr/Amort of Property" },
  DPRETY: { fullLabel: "DPRETY -- Depr/Amort of Property" },
  DPY: { fullLabel: "DPY -- Depreciation and Amortization - Total" },
  DRCQ: { fullLabel: "DRCQ -- Deferred Revenue - Current" },
  DRLTQ: { fullLabel: "DRLTQ -- Deferred Revenue - Long-term" },
  DTEAQ: { fullLabel: "DTEAQ -- Extinguishment of Debt After-tax" },
  DTEAY: { fullLabel: "DTEAY -- Extinguishment of Debt After-tax" },
  DTEDQ: { fullLabel: "DTEDQ -- Extinguishment of Debt Diluted EPS Effect" },
  DTEDY: { fullLabel: "DTEDY -- Extinguishment of Debt Diluted EPS Effect" },
  DTEEPSQ: { fullLabel: "DTEEPSQ -- Extinguishment of Debt Basic EPS Effect" },
  DTEEPSY: { fullLabel: "DTEEPSY -- Extinguishment of Debt Basic EPS Effect" },
  DTEPQ: { fullLabel: "DTEPQ -- Extinguishment of Debt Pretax" },
  DTEPY: { fullLabel: "DTEPY -- Extinguishment of Debt Pretax" },
  DVINTFQ: {
    fullLabel: "DVINTFQ -- Dividends & Interest Receivable (Cash Flow)",
  },
  DVPQ: { fullLabel: "DVPQ -- Dividends - Preferred/Preference" },
  DVPSPQ: { fullLabel: "DVPSPQ -- Dividends per Share - Pay Date - Quarter" },
  DVPSXQ: { fullLabel: "DVPSXQ -- Div per Share - Exdate - Quarter" },
  DVPY: { fullLabel: "DVPY -- Dividends - Preferred/Preference" },
  DVY: { fullLabel: "DVY -- Cash Dividends" },
  EIN: { fullLabel: "EIN -- Employer Identification Number" },
  EPSF12: {
    fullLabel:
      "EPSF12 -- Earnings Per Share (Diluted) - Excluding Extraordinary Items - 12 Months Mo",
  },
  EPSFI12: {
    fullLabel:
      "EPSFI12 -- Earnings Per Share (Diluted) - Including Extraordinary Items",
  },
  EPSFIQ: {
    fullLabel:
      "EPSFIQ -- Earnings Per Share (Diluted) - Including Extraordinary Items",
  },
  EPSFIY: {
    fullLabel:
      "EPSFIY -- Earnings Per Share (Diluted) - Including Extraordinary Items",
  },
  EPSFXQ: {
    fullLabel:
      "EPSFXQ -- Earnings Per Share (Diluted) - Excluding Extraordinary items",
  },
  EPSFXY: {
    fullLabel:
      "EPSFXY -- Earnings Per Share (Diluted) - Excluding Extraordinary items",
  },
  EPSPI12: {
    fullLabel:
      "EPSPI12 -- Earnings Per Share (Basic) - Including Extraordinary Items - 12 Months Movi",
  },
  EPSPIQ: {
    fullLabel:
      "EPSPIQ -- Earnings Per Share (Basic) - Including Extraordinary Items",
  },
  EPSPIY: {
    fullLabel:
      "EPSPIY -- Earnings Per Share (Basic) - Including Extraordinary Items",
  },
  EPSPXQ: {
    fullLabel:
      "EPSPXQ -- Earnings Per Share (Basic) - Excluding Extraordinary Items",
  },
  EPSPXY: {
    fullLabel:
      "EPSPXY -- Earnings Per Share (Basic) - Excluding Extraordinary Items",
  },
  EPSX12: {
    fullLabel:
      "EPSX12 -- Earnings Per Share (Basic) - Excluding Extraordinary Items - 12 Months Movi",
  },
  ESOPCTQ: { fullLabel: "ESOPCTQ -- Common ESOP Obligation - Total" },
  ESOPNRQ: {
    fullLabel: "ESOPNRQ -- Preferred ESOP Obligation - Non-Redeemable",
  },
  ESOPRQ: { fullLabel: "ESOPRQ -- Preferred ESOP Obligation - Redeemable" },
  ESOPTQ: { fullLabel: "ESOPTQ -- Preferred ESOP Obligation - Total" },
  ESUBCY: { fullLabel: "ESUBCY -- Equity in Net Loss/Earnings (C/F)" },
  ESUBQ: {
    fullLabel:
      "ESUBQ -- Equity in Earnings (I/S) - Unconsolidated Subsidiaries",
  },
  ESUBY: {
    fullLabel: "ESUBY -- Equity in Earnings (I/S)- Unconsolidated Subsidiaries",
  },
  EXCHG: { fullLabel: "Stock Exchange Code" },
  EXREY: { fullLabel: "EXREY -- Exchange Rate Effect" },
  FAX: { fullLabel: "FAX -- Fax Number" },
  FCAQ: { fullLabel: "FCAQ -- Foreign Exchange Income (Loss)" },
  FCAY: { fullLabel: "FCAY -- Foreign Exchange Income (Loss)" },
  FDATEQ: { fullLabel: "FDATEQ -- Final Date" },
  FFOQ: { fullLabel: "FFOQ -- Funds From Operations (REIT)" },
  FFOY: { fullLabel: "FFOY -- Funds From Operations (REIT)" },
  FIAOY: { fullLabel: "FIAOY -- Financing Activities - Other" },
  FIC: { fullLabel: "Foreign Incorporation Code" },
  FINACOQ: {
    fullLabel: "FINACOQ -- Finance Division Other Current Assets, Total",
  },
  FINALQ: { fullLabel: "FINALQ -- Final Indicator Flag" },
  FINAOQ: {
    fullLabel: "FINAOQ -- Finance Division Other Long-Term Assets, Total",
  },
  FINCFY: { fullLabel: "FINCFY -- Financing Activities - Net Cash Flow" },
  FINCHQ: { fullLabel: "FINCHQ -- Finance Division - Cash" },
  FINDLCQ: {
    fullLabel: "FINDLCQ -- Finance Division Long-Term Debt - Current",
  },
  FINDLTQ: { fullLabel: "FINDLTQ -- Finance Division Debt - Long-Term" },
  FINIVSTQ: {
    fullLabel: "FINIVSTQ -- Finance Division - Short-Term Investments",
  },
  FINLCOQ: {
    fullLabel: "FINLCOQ -- Finance Division Other Current Liabilities, Total",
  },
  FINLTOQ: {
    fullLabel: "FINLTOQ -- Finance Division Other Long Term Liabilities, Total",
  },
  FINNPQ: { fullLabel: "FINNPQ -- Finance Division Notes Payable" },
  FINRECCQ: { fullLabel: "FINRECCQ -- Finance Division - Current Receivables" },
  FINRECLTQ: {
    fullLabel: "FINRECLTQ -- Finance Division - Long-Term Receivables",
  },
  FINREVQ: { fullLabel: "FINREVQ -- Finance Division Revenue" },
  FINREVY: { fullLabel: "FINREVY -- Finance Division Revenue" },
  FINXINTQ: { fullLabel: "FINXINTQ -- Finance Division Interest Expense" },
  FINXINTY: { fullLabel: "FINXINTY -- Finance Division Interest Expense" },
  FINXOPRQ: { fullLabel: "FINXOPRQ -- Finance Division Operating Expense" },
  FINXOPRY: { fullLabel: "FINXOPRY -- Finance Division Operating Expense" },
  FOPOXY: {
    fullLabel:
      "FOPOXY -- Funds from Operations - Other excluding Option Tax Benefit",
  },
  FOPOY: { fullLabel: "FOPOY -- Funds from Operations - Other" },
  FOPTY: { fullLabel: "FOPTY -- Funds From Operations - Total" },
  FQTR: { fullLabel: "FQTR -- Fiscal Quarter" },
  FSRCOY: { fullLabel: "FSRCOY -- Sources of Funds - Other" },
  FSRCTY: { fullLabel: "FSRCTY -- Sources of Funds - Total" },
  FUSEOY: { fullLabel: "FUSEOY -- Uses of Funds - Other" },
  FUSETY: { fullLabel: "FUSETY -- Uses of Funds - Total" },
  FYEARQ: { fullLabel: "FYEARQ -- Fiscal Year" },
  FYR: { fullLabel: "Fiscal Year-End" },
  FYR: { fullLabel: "FYR -- Fiscal Year-end Month" },
  FYR: { fullLabel: "FYR -- Fiscal Year-end Month" },
  FYRC: { fullLabel: "FYRC -- Current Fiscal Year End Month" },
  GDWLAMQ: { fullLabel: "GDWLAMQ -- Amortization of Goodwill" },
  GDWLAMY: { fullLabel: "GDWLAMY -- Amortization of Goodwill" },
  GDWLIA12: {
    fullLabel: "GDWLIA12 -- Impairments of Goodwill AfterTax - 12mm",
  },
  GDWLIAQ: { fullLabel: "GDWLIAQ -- Impairment of Goodwill After-tax" },
  GDWLIAY: { fullLabel: "GDWLIAY -- Impairment of Goodwill After-tax" },
  GDWLID12: { fullLabel: "GDWLID12 -- Impairments Diluted EPS - 12mm" },
  GDWLIDQ: {
    fullLabel: "GDWLIDQ -- Impairment of Goodwill Diluted EPS Effect",
  },
  GDWLIDY: {
    fullLabel: "GDWLIDY -- Impairment of Goodwill Diluted EPS Effect",
  },
  GDWLIEPS12: {
    fullLabel: "GDWLIEPS12 -- Impairment of Goodwill Basic EPS Effect 12MM",
  },
  GDWLIEPSQ: {
    fullLabel: "GDWLIEPSQ -- Impairment of Goodwill Basic EPS Effect",
  },
  GDWLIEPSY: {
    fullLabel: "GDWLIEPSY -- Impairment of Goodwill Basic EPS Effect",
  },
  GDWLIPQ: { fullLabel: "GDWLIPQ -- Impairment of Goodwill Pretax" },
  GDWLIPY: { fullLabel: "GDWLIPY -- Impairment of Goodwill Pretax" },
  GDWLQ: { fullLabel: "GDWLQ -- Goodwill (net)" },
  GGROUP: { fullLabel: "GGROUP -- GIC Groups" },
  GIND: { fullLabel: "GIND -- GIC Industries" },
  GLAQ: { fullLabel: "GLAQ -- Gain/Loss After-Tax" },
  GLAY: { fullLabel: "GLAY -- Gain/Loss After-Tax" },
  GLCEA12: {
    fullLabel:
      "GLCEA12 -- Gain/Loss on Sale (Core Earnings Adjusted) After-tax 12MM",
  },
  GLCEAQ: {
    fullLabel: "GLCEAQ -- Gain/Loss on Sale (Core Earnings Adjusted) After-tax",
  },
  GLCEAY: {
    fullLabel: "GLCEAY -- Gain/Loss on Sale (Core Earnings Adjusted) After-tax",
  },
  GLCED12: {
    fullLabel:
      "GLCED12 -- Gain/Loss on Sale (Core Earnings Adjusted) Diluted EPS Effect 12MM",
  },
  GLCEDQ: {
    fullLabel:
      "GLCEDQ -- Gain/Loss on Sale (Core Earnings Adjusted) Diluted EPS",
  },
  GLCEDY: {
    fullLabel:
      "GLCEDY -- Gain/Loss on Sale (Core Earnings Adjusted) Diluted EPS",
  },
  GLCEEPS12: {
    fullLabel:
      "GLCEEPS12 -- Gain/Loss on Sale (Core Earnings Adjusted) Basic EPS Effect 12MM",
  },
  GLCEEPSQ: {
    fullLabel:
      "GLCEEPSQ -- Gain/Loss on Sale (Core Earnings Adjusted) Basic EPS Effect",
  },
  GLCEEPSY: {
    fullLabel:
      "GLCEEPSY -- Gain/Loss on Sale (Core Earnings Adjusted) Basic EPS Effect",
  },
  GLCEPQ: {
    fullLabel: "GLCEPQ -- Gain/Loss on Sale (Core Earnings Adjusted) Pretax",
  },
  GLCEPY: {
    fullLabel: "GLCEPY -- Gain/Loss on Sale (Core Earnings Adjusted) Pretax",
  },
  GLDQ: { fullLabel: "GLDQ -- Gain/Loss Diluted EPS Effect" },
  GLDY: { fullLabel: "GLDY -- Gain/Loss Diluted EPS Effect" },
  GLEPSQ: { fullLabel: "GLEPSQ -- Gain/Loss Basic EPS Effect" },
  GLEPSY: { fullLabel: "GLEPSY -- Gain/Loss Basic EPS Effect" },
  GLIVQ: { fullLabel: "GLIVQ -- Gains/Losses on investments" },
  GLIVY: { fullLabel: "GLIVY -- Gains/Losses on investments" },
  GLPQ: { fullLabel: "GLPQ -- Gain/Loss Pretax" },
  GLPY: { fullLabel: "GLPY -- Gain/Loss Pretax" },
  GSECTOR: { fullLabel: "GSECTOR -- GIC Sectors" },
  GSUBIND: { fullLabel: "GSUBIND -- GIC Sub-Industries" },
  HEDGEGLQ: { fullLabel: "HEDGEGLQ -- Gain/Loss on Ineffective Hedges" },
  HEDGEGLY: { fullLabel: "HEDGEGLY -- Gain/Loss on Ineffective Hedges" },
  IBADJ12: {
    fullLabel:
      "IBADJ12 -- Income Before Extra Items - Adj for Common Stock Equivalents - 12MM",
  },
  IBADJQ: {
    fullLabel:
      "IBADJQ -- Income Before Extraordinary Items - Adjusted for Common Stock Equivalents",
  },
  IBADJY: {
    fullLabel:
      "IBADJY -- Income Before Extraordinary Items - Adjusted for Common Stock Equivalents",
  },
  IBCOMQ: {
    fullLabel:
      "IBCOMQ -- Income Before Extraordinary Items - Available for Common",
  },
  IBCOMY: {
    fullLabel:
      "IBCOMY -- Income Before Extraordinary Items - Available for Common",
  },
  IBCY: {
    fullLabel:
      "IBCY -- Income Before Extraordinary Items - Statement of Cash Flows",
  },
  IBMIIQ: {
    fullLabel:
      "IBMIIQ -- Income before Extraordinary Items and Noncontrolling Interests",
  },
  IBMIIY: {
    fullLabel:
      "IBMIIY -- Income before Extraordinary Items and Noncontrolling Interests",
  },
  IBQ: { fullLabel: "IBQ -- Income Before Extraordinary Items" },
  IBY: { fullLabel: "IBY -- Income Before Extraordinary Items" },
  ICAPTQ: { fullLabel: "ICAPTQ -- Invested Capital - Total - Quarterly" },
  IDBFLAG: { fullLabel: "IDBFLAG -- International, Domestic, Both Indicator" },
  INCORP: {
    fullLabel: "INCORP -- Current State/Province of Incorporation Code",
  },
  INTACCQ: { fullLabel: "INTACCQ -- Interest Accrued" },
  INTANOQ: { fullLabel: "INTANOQ -- Other Intangibles" },
  INTANQ: { fullLabel: "INTANQ -- Intangible Assets - Total" },
  INTPNY: { fullLabel: "INTPNY -- Interest Paid - Net" },
  INVCHY: { fullLabel: "INVCHY -- Inventory - Decrease (Increase)" },
  INVFGQ: { fullLabel: "INVFGQ -- Inventory - Finished Goods" },
  INVOQ: { fullLabel: "INVOQ -- Inventory - Other" },
  INVRMQ: { fullLabel: "INVRMQ -- Inventory - Raw Materials" },
  INVTQ: { fullLabel: "INVTQ -- Inventories - Total" },
  INVWIPQ: { fullLabel: "INVWIPQ -- Inventory - Work in Process" },
  IPODATE: { fullLabel: "IPODATE -- Company Initial Public Offering Date" },
  ITCCY: { fullLabel: "ITCCY -- Investment Tax Credit - Net (Cash Flow)" },
  IVACOY: { fullLabel: "IVACOY -- Investing Activities - Other" },
  IVAEQQ: { fullLabel: "IVAEQQ -- Investment and Advances - Equity" },
  IVAOQ: { fullLabel: "IVAOQ -- Investment and Advances - Other" },
  IVCHY: { fullLabel: "IVCHY -- Increase in Investments" },
  IVLTQ: { fullLabel: "IVLTQ -- Total Long-term Investments" },
  IVNCFY: { fullLabel: "IVNCFY -- Investing Activities - Net Cash Flow" },
  IVSTCHY: { fullLabel: "IVSTCHY -- Short-Term Investments - Change" },
  IVSTQ: { fullLabel: "IVSTQ -- Short-Term Investments- Total" },
  LCOQ: { fullLabel: "LCOQ -- Current Liabilities - Other - Total" },
  LCTQ: { fullLabel: "LCTQ -- Current Liabilities - Total" },
  LLTQ: { fullLabel: "LLTQ -- Long-Term Liabilities (Total)" },
  LNOQ: { fullLabel: "LNOQ -- Liabilities Netting & Other Adjustments" },
  LOC: { fullLabel: "LOC -- Current ISO Country Code - Headquarters" },
  LOL2Q: { fullLabel: "LOL2Q -- Liabilities Level2 (Observable)" },
  LOQ: { fullLabel: "LOQ -- Liabilities - Other" },
  LOXDRQ: {
    fullLabel: "LOXDRQ -- Liabilities - Other - Excluding Deferred Revenue",
  },
  LQPL1Q: { fullLabel: "LQPL1Q -- Liabilities Level1 (Quoted Prices)" },
  LSEQ: { fullLabel: "LSEQ -- Liabilities and Stockholders Equity - Total" },
  LTMIBQ: {
    fullLabel: "LTMIBQ -- Liabilities - Total and Noncontrolling Interest",
  },
  LTQ: { fullLabel: "LTQ -- Liabilities - Total" },
  LUL3Q: { fullLabel: "LUL3Q -- Liabilities Level3 (Unobservable)" },
  MIBNQ: {
    fullLabel:
      "MIBNQ -- Noncontrolling Interests - Nonredeemable - Balance Sheet",
  },
  MIBQ: {
    fullLabel: "MIBQ -- Noncontrolling Interest - Redeemable - Balance Sheet",
  },
  MIBTQ: {
    fullLabel: "MIBTQ -- Noncontrolling Interests - Total - Balance Sheet",
  },
  MIIQ: { fullLabel: "MIIQ -- Noncontrolling Interest - Income Account" },
  MIIY: { fullLabel: "MIIY -- Noncontrolling Interest - Income Account" },
  MKVALTQ: { fullLabel: "MKVALTQ -- Market Value - Total" },
  MSAQ: {
    fullLabel: "MSAQ -- Accum Other Comp Inc - Marketable Security Adjustments",
  },
  NAICS: { fullLabel: "NAICS -- North American Industry Classification Code" },
  NCOQ: { fullLabel: "NCOQ -- Net Charge-Offs" },
  NCOY: { fullLabel: "NCOY -- Net Charge-Offs" },
  NIITQ: { fullLabel: "NIITQ -- Net Interest Income (Tax Equivalent)" },
  NIITY: { fullLabel: "NIITY -- Net Interest Income (Tax Equivalent)" },
  NIMQ: { fullLabel: "NIMQ -- Net Interest Margin" },
  NIMY: { fullLabel: "NIMY -- Net Interest Margin" },
  NIQ: { fullLabel: "NIQ -- Net Income (Loss)" },
  NIY: { fullLabel: "NIY -- Net Income (Loss)" },
  NOPIQ: { fullLabel: "NOPIQ -- Non-Operating Income (Expense) - Total" },
  NOPIY: { fullLabel: "NOPIY -- Non-Operating Income (Expense) - Total" },
  NPATQ: { fullLabel: "NPATQ -- Nonperforming Assets - Total" },
  NPQ: { fullLabel: "NPQ -- Notes Payable" },
  NRTXTDQ: {
    fullLabel: "NRTXTDQ -- Nonrecurring Income Taxes Diluted EPS Effect",
  },
  NRTXTDY: {
    fullLabel: "NRTXTDY -- Nonrecurring Income Taxes Diluted EPS Effect",
  },
  NRTXTEPSQ: {
    fullLabel: "NRTXTEPSQ -- Nonrecurring Income Taxes Basic EPS Effect",
  },
  NRTXTEPSY: {
    fullLabel: "NRTXTEPSY -- Nonrecurring Income Taxes Basic EPS Effect",
  },
  NRTXTQ: { fullLabel: "NRTXTQ -- Nonrecurring Income Taxes - After-tax" },
  NRTXTY: { fullLabel: "NRTXTY -- Nonrecurring Income Taxes - After-tax" },
  OANCFY: { fullLabel: "OANCFY -- Operating Activities - Net Cash Flow" },
  OBKQ: { fullLabel: "OBKQ -- Order backlog" },
  OEPF12: {
    fullLabel:
      "OEPF12 -- Earnings Per Share - Diluted - from Operations - 12MM",
  },
  OEPS12: {
    fullLabel:
      "OEPS12 -- Earnings Per Share from Operations - 12 Months Moving",
  },
  OEPSXQ: {
    fullLabel: "OEPSXQ -- Earnings Per Share - Diluted - from Operations",
  },
  OEPSXY: {
    fullLabel: "OEPSXY -- Earnings Per Share - Diluted - from Operations",
  },
  OGMQ: { fullLabel: "OGMQ -- OIL & GAS METHOD" },
  OIADPQ: {
    fullLabel: "OIADPQ -- Operating Income After Depreciation - Quarterly",
  },
  OIADPY: {
    fullLabel: "OIADPY -- Operating Income After Depreciation - Year-to-Date",
  },
  OIBDPQ: {
    fullLabel: "OIBDPQ -- Operating Income Before Depreciation - Quarterly",
  },
  OIBDPY: { fullLabel: "OIBDPY -- Operating Income Before Depreciation" },
  OPEPSQ: { fullLabel: "OPEPSQ -- Earnings Per Share from Operations" },
  OPEPSY: { fullLabel: "OPEPSY -- Earnings Per Share from Operations" },
  OPTDRQ: { fullLabel: "OPTDRQ -- Dividend Rate - Assumption (%)" },
  OPTDRY: { fullLabel: "OPTDRY -- Dividend Rate - Assumption (%)" },
  OPTFVGRQ: {
    fullLabel: "OPTFVGRQ -- Options - Fair Value of Options Granted",
  },
  OPTFVGRY: {
    fullLabel: "OPTFVGRY -- Options - Fair Value of Options Granted",
  },
  OPTLIFEQ: { fullLabel: "OPTLIFEQ -- Life of Options - Assumption (## yrs)" },
  OPTLIFEY: { fullLabel: "OPTLIFEY -- Life of Options - Assumption (## yrs)" },
  OPTRFRQ: { fullLabel: "OPTRFRQ -- Risk Free Rate - Assumption (%)" },
  OPTRFRY: { fullLabel: "OPTRFRY -- Risk Free Rate - Assumption (%)" },
  OPTVOLQ: { fullLabel: "OPTVOLQ -- Volatility - Assumption (%)" },
  OPTVOLY: { fullLabel: "OPTVOLY -- Volatility - Assumption (%)" },
  PDATEQ: { fullLabel: "PDATEQ -- Preliminary Date" },
  PDVCY: {
    fullLabel:
      "PDVCY -- Cash Dividends on Preferred/Preference Stock (Cash Flow)",
  },
  PHONE: { fullLabel: "PHONE -- Phone Number" },
  PIQ: { fullLabel: "PIQ -- Pretax Income" },
  PIY: { fullLabel: "PIY -- Pretax Income" },
  PLLQ: { fullLabel: "PLLQ -- Provision for Loan/Asset Losses" },
  PLLY: { fullLabel: "PLLY -- Provision for Loan/Asset Losses" },
  PNC12: { fullLabel: "PNC12 -- Pension Core Adjustment - 12mm" },
  PNCD12: {
    fullLabel: "PNCD12 -- Core Pension Adjustment Diluted EPS Effect 12MM",
  },
  PNCDQ: { fullLabel: "PNCDQ -- Core Pension Adjustment Diluted EPS Effect" },
  PNCDY: { fullLabel: "PNCDY -- Core Pension Adjustment Diluted EPS Effect" },
  PNCEPS12: {
    fullLabel: "PNCEPS12 -- Core Pension Adjustment Basic EPS Effect 12MM",
  },
  PNCEPSQ: { fullLabel: "PNCEPSQ -- Core Pension Adjustment Basic EPS Effect" },
  PNCEPSY: { fullLabel: "PNCEPSY -- Core Pension Adjustment Basic EPS Effect" },
  PNCIAPQ: {
    fullLabel:
      "PNCIAPQ -- Core Pension Interest Adjustment After-tax Preliminary",
  },
  PNCIAPY: {
    fullLabel:
      "PNCIAPY -- Core Pension Interest Adjustment After-tax Preliminary",
  },
  PNCIAQ: { fullLabel: "PNCIAQ -- Core Pension Interest Adjustment After-tax" },
  PNCIAY: { fullLabel: "PNCIAY -- Core Pension Interest Adjustment After-tax" },
  PNCIDPQ: {
    fullLabel:
      "PNCIDPQ -- Core Pension Interest Adjustment Diluted EPS Effect Preliminary",
  },
  PNCIDPY: {
    fullLabel:
      "PNCIDPY -- Core Pension Interest Adjustment Diluted EPS Effect Preliminary",
  },
  PNCIDQ: {
    fullLabel: "PNCIDQ -- Core Pension Interest Adjustment Diluted EPS Effect",
  },
  PNCIDY: {
    fullLabel: "PNCIDY -- Core Pension Interest Adjustment Diluted EPS Effect",
  },
  PNCIEPSPQ: {
    fullLabel:
      "PNCIEPSPQ -- Core Pension Interest Adjustment Basic EPS Effect Preliminary",
  },
  PNCIEPSPY: {
    fullLabel:
      "PNCIEPSPY -- Core Pension Interest Adjustment Basic EPS Effect Preliminary",
  },
  PNCIEPSQ: {
    fullLabel: "PNCIEPSQ -- Core Pension Interest Adjustment Basic EPS Effect",
  },
  PNCIEPSY: {
    fullLabel: "PNCIEPSY -- Core Pension Interest Adjustment Basic EPS Effect",
  },
  PNCIPPQ: {
    fullLabel: "PNCIPPQ -- Core Pension Interest Adjustment Pretax Preliminary",
  },
  PNCIPPY: {
    fullLabel: "PNCIPPY -- Core Pension Interest Adjustment Pretax Preliminary",
  },
  PNCIPQ: { fullLabel: "PNCIPQ -- Core Pension Interest Adjustment Pretax" },
  PNCIPY: { fullLabel: "PNCIPY -- Core Pension Interest Adjustment Pretax" },
  PNCPD12: {
    fullLabel:
      "PNCPD12 -- Core Pension Adjustment 12MM Diluted EPS Effect Preliminary",
  },
  PNCPDQ: {
    fullLabel:
      "PNCPDQ -- Core Pension Adjustment Diluted EPS Effect Preliminary",
  },
  PNCPDY: {
    fullLabel:
      "PNCPDY -- Core Pension Adjustment Diluted EPS Effect Preliminary",
  },
  PNCPEPS12: {
    fullLabel:
      "PNCPEPS12 -- Core Pension Adjustment 12MM Basic EPS Effect Preliminary",
  },
  PNCPEPSQ: {
    fullLabel:
      "PNCPEPSQ -- Core Pension Adjustment Basic EPS Effect Preliminary",
  },
  PNCPEPSY: {
    fullLabel:
      "PNCPEPSY -- Core Pension Adjustment Basic EPS Effect Preliminary",
  },
  PNCPQ: { fullLabel: "PNCPQ -- Core Pension Adjustment Preliminary" },
  PNCPY: { fullLabel: "PNCPY -- Core Pension Adjustment Preliminary" },
  PNCQ: { fullLabel: "PNCQ -- Core Pension Adjustment" },
  PNCWIAPQ: {
    fullLabel:
      "PNCWIAPQ -- Core Pension w/o Interest Adjustment After-tax Preliminary",
  },
  PNCWIAPY: {
    fullLabel:
      "PNCWIAPY -- Core Pension w/o Interest Adjustment After-tax Preliminary",
  },
  PNCWIAQ: {
    fullLabel: "PNCWIAQ -- Core Pension w/o Interest Adjustment After-tax",
  },
  PNCWIAY: {
    fullLabel: "PNCWIAY -- Core Pension w/o Interest Adjustment After-tax",
  },
  PNCWIDPQ: {
    fullLabel:
      "PNCWIDPQ -- Core Pension w/o Interest Adjustment Diluted EPS Effect Preliminary",
  },
  PNCWIDPY: {
    fullLabel:
      "PNCWIDPY -- Core Pension w/o Interest Adjustment Diluted EPS Effect Preliminary",
  },
  PNCWIDQ: {
    fullLabel:
      "PNCWIDQ -- Core Pension w/o Interest Adjustment Diluted EPS Effect",
  },
  PNCWIDY: {
    fullLabel:
      "PNCWIDY -- Core Pension w/o Interest Adjustment Diluted EPS Effect",
  },
  PNCWIEPQ: {
    fullLabel:
      "PNCWIEPQ -- Core Pension w/o Interest Adjustment Basic EPS Effect Preliminary",
  },
  PNCWIEPSQ: {
    fullLabel:
      "PNCWIEPSQ -- Core Pension w/o Interest Adjustment Basic EPS Effect",
  },
  PNCWIEPSY: {
    fullLabel:
      "PNCWIEPSY -- Core Pension w/o Interest Adjustment Basic EPS Effect",
  },
  PNCWIEPY: {
    fullLabel:
      "PNCWIEPY -- Core Pension w/o Interest Adjustment Basic EPS Effect Preliminary",
  },
  PNCWIPPQ: {
    fullLabel:
      "PNCWIPPQ -- Core Pension w/o Interest Adjustment Pretax Preliminary",
  },
  PNCWIPPY: {
    fullLabel:
      "PNCWIPPY -- Core Pension w/o Interest Adjustment Pretax Preliminary",
  },
  PNCWIPQ: {
    fullLabel: "PNCWIPQ -- Core Pension w/o Interest Adjustment Pretax",
  },
  PNCWIPY: {
    fullLabel: "PNCWIPY -- Core Pension w/o Interest Adjustment Pretax",
  },
  PNCY: { fullLabel: "PNCY -- Core Pension Adjustment" },
  PNRSHOQ: { fullLabel: "PNRSHOQ -- Nonred Pfd Shares Outs (000) - Quarterly" },
  PPEGTQ: {
    fullLabel:
      "PPEGTQ -- Property, Plant and Equipment - Total (Gross) - Quarterly",
  },
  PPENTQ: { fullLabel: "PPENTQ -- Property Plant and Equipment - Total (Net)" },
  PRCAQ: { fullLabel: "PRCAQ -- Core Post Retirement Adjustment" },
  PRCAY: { fullLabel: "PRCAY -- Core Post Retirement Adjustment" },
  PRCCQ: { fullLabel: "PRCCQ -- Price Close - Quarter" },
  PRCD12: {
    fullLabel:
      "PRCD12 -- Core Post Retirement Adjustment Diluted EPS Effect 12MM",
  },
  PRCDQ: {
    fullLabel: "PRCDQ -- Core Post Retirement Adjustment Diluted EPS Effect",
  },
  PRCDY: {
    fullLabel: "PRCDY -- Core Post Retirement Adjustment Diluted EPS Effect",
  },
  PRCE12: { fullLabel: "PRCE12 -- Core Post Retirement Adjustment 12MM" },
  PRCEPS12: {
    fullLabel:
      "PRCEPS12 -- Core Post Retirement Adjustment Basic EPS Effect 12MM",
  },
  PRCEPSQ: {
    fullLabel: "PRCEPSQ -- Core Post Retirement Adjustment Basic EPS Effect",
  },
  PRCEPSY: {
    fullLabel: "PRCEPSY -- Core Post Retirement Adjustment Basic EPS Effect",
  },
  PRCHQ: { fullLabel: "PRCHQ -- Price High - Quarter" },
  PRCLQ: { fullLabel: "PRCLQ -- Price Low - Quarter" },
  PRCPD12: {
    fullLabel:
      "PRCPD12 -- Core Post Retirement Adjustment 12MM Diluted EPS Effect Preliminary",
  },
  PRCPDQ: {
    fullLabel:
      "PRCPDQ -- Core Post Retirement Adjustment Diluted EPS Effect Preliminary",
  },
  PRCPDY: {
    fullLabel:
      "PRCPDY -- Core Post Retirement Adjustment Diluted EPS Effect Preliminary",
  },
  PRCPEPS12: {
    fullLabel:
      "PRCPEPS12 -- Core Post Retirement Adjustment 12MM Basic EPS Effect Preliminary",
  },
  PRCPEPSQ: {
    fullLabel:
      "PRCPEPSQ -- Core Post Retirement Adjustment Basic EPS Effect Preliminary",
  },
  PRCPEPSY: {
    fullLabel:
      "PRCPEPSY -- Core Post Retirement Adjustment Basic EPS Effect Preliminary",
  },
  PRCPQ: { fullLabel: "PRCPQ -- Core Post Retirement Adjustment Preliminary" },
  PRCPY: { fullLabel: "PRCPY -- Core Post Retirement Adjustment Preliminary" },
  PRCRAQ: {
    fullLabel: "PRCRAQ -- Repurchase Price - Average per share Quarter",
  },
  PRICAN: { fullLabel: "PRICAN -- Current Primary Issue Tag - Canada" },
  PRIROW: { fullLabel: "PRIROW -- Primary Issue Tag - Rest of World" },
  PRIUSA: { fullLabel: "PRIUSA -- Current Primary Issue Tag - US" },
  PRSHOQ: { fullLabel: "PRSHOQ -- Redeem Pfd Shares Outs (000)" },
  PRSTKCCY: { fullLabel: "PRSTKCCY -- Purchase of Common Stock (Cash Flow)" },
  PRSTKCY: { fullLabel: "PRSTKCY -- Purchase of Common and Preferred Stock" },
  PRSTKPCY: {
    fullLabel: "PRSTKPCY -- Purchase of Preferred/Preference Stock (Cash Flow)",
  },
  PSTKNQ: { fullLabel: "PSTKNQ -- Preferred/Preference Stock - Nonredeemable" },
  PSTKQ: { fullLabel: "PSTKQ -- Preferred/Preference Stock (Capital) - Total" },
  PSTKRQ: { fullLabel: "PSTKRQ -- Preferred/Preference Stock - Redeemable" },
  RCAQ: { fullLabel: "RCAQ -- Restructuring Cost After-tax" },
  RCAY: { fullLabel: "RCAY -- Restructuring Cost After-tax" },
  RCDQ: { fullLabel: "RCDQ -- Restructuring Cost Diluted EPS Effect" },
  RCDY: { fullLabel: "RCDY -- Restructuring Cost Diluted EPS Effect" },
  RCEPSQ: { fullLabel: "RCEPSQ -- Restructuring Cost Basic EPS Effect" },
  RCEPSY: { fullLabel: "RCEPSY -- Restructuring Cost Basic EPS Effect" },
  RCPQ: { fullLabel: "RCPQ -- Restructuring Cost Pretax" },
  RCPY: { fullLabel: "RCPY -- Restructuring Cost Pretax" },
  RDIPAQ: { fullLabel: "RDIPAQ -- In Process R&D Expense After-tax" },
  RDIPAY: { fullLabel: "RDIPAY -- In Process R&D Expense After-tax" },
  RDIPDQ: { fullLabel: "RDIPDQ -- In Process R&D Expense Diluted EPS Effect" },
  RDIPDY: { fullLabel: "RDIPDY -- In Process R&D Expense Diluted EPS Effect" },
  RDIPEPSQ: {
    fullLabel: "RDIPEPSQ -- In Process R&D Expense Basic EPS Effect",
  },
  RDIPEPSY: {
    fullLabel: "RDIPEPSY -- In Process R&D Expense Basic EPS Effect",
  },
  RDIPQ: { fullLabel: "RDIPQ -- In Process R&D" },
  RDIPY: { fullLabel: "RDIPY -- In Process R&D" },
  RDQ: { fullLabel: "RDQ -- Report Date of Quarterly Earnings" },
  RECCHY: { fullLabel: "RECCHY -- Accounts Receivable - Decrease (Increase)" },
  RECDQ: { fullLabel: "RECDQ -- Receivables - Estimated Doubtful" },
  RECTAQ: {
    fullLabel:
      "RECTAQ -- Accum Other Comp Inc - Cumulative Translation Adjustments",
  },
  RECTOQ: {
    fullLabel: "RECTOQ -- Receivables - Current Other incl Tax Refunds",
  },
  RECTQ: { fullLabel: "RECTQ -- Receivables - Total" },
  RECTRQ: { fullLabel: "RECTRQ -- Receivables - Trade" },
  RECUBQ: { fullLabel: "RECUBQ -- Unbilled Receivables - Quarterly" },
  REQ: { fullLabel: "REQ -- Retained Earnings" },
  RETQ: { fullLabel: "RETQ -- Total RE Property" },
  REUNAQ: { fullLabel: "REUNAQ -- Unadjusted Retained Earnings" },
  REVTQ: { fullLabel: "REVTQ -- Qtr Revenue - Total" },
  REVTY: { fullLabel: "REVTY -- Revenue - Total" },
  RLLQ: { fullLabel: "RLLQ -- Reserve for Loan/Asset Losses" },
  RP: { fullLabel: "RP -- Reporting Periodicity" },
  RRA12: {
    fullLabel: "RRA12 -- Reversal - Restructruring/Acquisition Aftertax 12MM",
  },
  RRAQ: { fullLabel: "RRAQ -- Reversal - Restructruring/Acquisition Aftertax" },
  RRAY: { fullLabel: "RRAY -- Reversal - Restructruring/Acquisition Aftertax" },
  RRD12: {
    fullLabel: "RRD12 -- Reversal - Restructuring/Acq Diluted EPS Effect 12MM",
  },
  RRDQ: {
    fullLabel: "RRDQ -- Reversal - Restructuring/Acq Diluted EPS Effect",
  },
  RRDY: {
    fullLabel: "RRDY -- Reversal - Restructuring/Acq Diluted EPS Effect",
  },
  RREPS12: {
    fullLabel: "RREPS12 -- Reversal - Restructuring/Acq Basic EPS Effect 12MM",
  },
  RREPSQ: {
    fullLabel: "RREPSQ -- Reversal - Restructuring/Acq Basic EPS Effect",
  },
  RREPSY: {
    fullLabel: "RREPSY -- Reversal - Restructuring/Acq Basic EPS Effect",
  },
  RRPQ: { fullLabel: "RRPQ -- Reversal - Restructruring/Acquisition Pretax" },
  RRPY: { fullLabel: "RRPY -- Reversal - Restructruring/Acquisition Pretax" },
  RSTCHELTQ: {
    fullLabel: "RSTCHELTQ -- Long-Term Restricted Cash & Investments",
  },
  RSTCHEQ: { fullLabel: "RSTCHEQ -- Restricted Cash & Investments - Current" },
  SALEQ: { fullLabel: "SALEQ -- Sales/Turnover (Net)" },
  SALEY: { fullLabel: "SALEY -- Sales/Turnover (Net)" },
  SCFQ: { fullLabel: "SCFQ -- Cash Flow Model" },
  SCSTKCY: { fullLabel: "SCSTKCY -- Sale of Common Stock (Cash Flow)" },
  SEQOQ: { fullLabel: "SEQOQ -- Other Stockholders- Equity Adjustments" },
  SEQQ: {
    fullLabel:
      "SEQQ -- Stockholders Equity > Parent > Index Fundamental > Quarterly",
  },
  SETA12: {
    fullLabel: "SETA12 -- Settlement (Litigation/Insurance) AfterTax - 12mm",
  },
  SETAQ: { fullLabel: "SETAQ -- Settlement (Litigation/Insurance) After-tax" },
  SETAY: { fullLabel: "SETAY -- Settlement (Litigation/Insurance) After-tax" },
  SETD12: {
    fullLabel:
      "SETD12 -- Settlement (Litigation/Insurance) Diluted EPS Effect 12MM",
  },
  SETDQ: {
    fullLabel: "SETDQ -- Settlement (Litigation/Insurance) Diluted EPS Effect",
  },
  SETDY: {
    fullLabel: "SETDY -- Settlement (Litigation/Insurance) Diluted EPS Effect",
  },
  SETEPS12: {
    fullLabel:
      "SETEPS12 -- Settlement (Litigation/Insurance) Basic EPS Effect 12MM",
  },
  SETEPSQ: {
    fullLabel: "SETEPSQ -- Settlement (Litigation/Insurance) Basic EPS Effect",
  },
  SETEPSY: {
    fullLabel: "SETEPSY -- Settlement (Litigation/Insurance) Basic EPS Effect",
  },
  SETPQ: { fullLabel: "SETPQ -- Settlement (Litigation/Insurance) Pretax" },
  SETPY: { fullLabel: "SETPY -- Settlement (Litigation/Insurance) Pretax" },
  SIC: { fullLabel: "SIC -- Standard Industry Classification Code" },
  SIVY: { fullLabel: "SIVY -- Sale of Investments" },
  SPCE12: { fullLabel: "SPCE12 -- S&P Core Earnings 12MM" },
  SPCED12: { fullLabel: "SPCED12 -- S&P Core Earnings EPS Diluted 12MM" },
  SPCEDPQ: {
    fullLabel: "SPCEDPQ -- S&P Core Earnings EPS Diluted - Preliminary",
  },
  SPCEDPY: {
    fullLabel: "SPCEDPY -- S&P Core Earnings EPS Diluted - Preliminary",
  },
  SPCEDQ: { fullLabel: "SPCEDQ -- S&P Core Earnings EPS Diluted" },
  SPCEDY: { fullLabel: "SPCEDY -- S&P Core Earnings EPS Diluted" },
  SPCEEPS12: { fullLabel: "SPCEEPS12 -- S&P Core Earnings EPS Basic 12MM" },
  SPCEEPSP12: {
    fullLabel: "SPCEEPSP12 -- S&P Core 12MM EPS - Basic - Preliminary",
  },
  SPCEEPSPQ: {
    fullLabel: "SPCEEPSPQ -- S&P Core Earnings EPS Basic - Preliminary",
  },
  SPCEEPSPY: {
    fullLabel: "SPCEEPSPY -- S&P Core Earnings EPS Basic - Preliminary",
  },
  SPCEEPSQ: { fullLabel: "SPCEEPSQ -- S&P Core Earnings EPS Basic" },
  SPCEEPSY: { fullLabel: "SPCEEPSY -- S&P Core Earnings EPS Basic" },
  SPCEP12: { fullLabel: "SPCEP12 -- S&P Core Earnings 12MM - Preliminary" },
  SPCEPD12: {
    fullLabel: "SPCEPD12 -- S&P Core Earnings 12MM EPS Diluted - Preliminary",
  },
  SPCEPQ: { fullLabel: "SPCEPQ -- S&P Core Earnings - Preliminary" },
  SPCEPY: { fullLabel: "SPCEPY -- S&P Core Earnings - Preliminary" },
  SPCEQ: { fullLabel: "SPCEQ -- S&P Core Earnings" },
  SPCEY: { fullLabel: "SPCEY -- S&P Core Earnings" },
  SPCINDCD: { fullLabel: "SPCINDCD -- S&P Industry Sector Code" },
  SPCSECCD: { fullLabel: "SPCSECCD -- S&P Economic Sector Code" },
  SPCSRC: { fullLabel: "SPCSRC -- S&P Quality Ranking - Current" },
  SPIDQ: { fullLabel: "SPIDQ -- Other Special Items Diluted EPS Effect" },
  SPIDY: { fullLabel: "SPIDY -- Other Special Items Diluted EPS Effect" },
  SPIEPSQ: { fullLabel: "SPIEPSQ -- Other Special Items Basic EPS Effect" },
  SPIEPSY: { fullLabel: "SPIEPSY -- Other Special Items Basic EPS Effect" },
  SPIOAQ: { fullLabel: "SPIOAQ -- Other Special Items After-tax" },
  SPIOAY: { fullLabel: "SPIOAY -- Other Special Items After-tax" },
  SPIOPQ: { fullLabel: "SPIOPQ -- Other Special Items Pretax" },
  SPIOPY: { fullLabel: "SPIOPY -- Other Special Items Pretax" },
  SPIQ: { fullLabel: "SPIQ -- Special Items" },
  SPIY: { fullLabel: "SPIY -- Special Items" },
  SPPEY: { fullLabel: "SPPEY -- Sale of Property" },
  SPPIVY: { fullLabel: "SPPIVY -- Sale of PP&E and Investments - (Gain) Loss" },
  SPSTKCY: {
    fullLabel: "SPSTKCY -- Sale of Preferred/Preference Stock (Cash Flow)",
  },
  SRCQ: { fullLabel: "SRCQ -- Source Code" },
  SRETQ: { fullLabel: "SRETQ -- Gain/Loss on Sale of Property" },
  SRETY: { fullLabel: "SRETY -- Gain/Loss on Sale of Property" },
  SSTKY: { fullLabel: "SSTKY -- Sale of Common and Preferred Stock" },
  STALTQ: { fullLabel: "STALTQ -- Status Alert" },
  STATE: { fullLabel: "STATE -- State/Province" },
  STKCOQ: { fullLabel: "STKCOQ -- Stock Compensation Expense" },
  STKCOY: { fullLabel: "STKCOY -- Stock Compensation Expense" },
  STKCPAQ: { fullLabel: "STKCPAQ -- After-tax stock compensation" },
  STKCPAY: { fullLabel: "STKCPAY -- After-tax stock compensation" },
  STKO: { fullLabel: "STKO -- Stock Ownership Code" },
  TDCY: { fullLabel: "TDCY -- Deferred Income Taxes - Net (Cash Flow)" },
  TEQQ: { fullLabel: "TEQQ -- Stockholders Equity - Total" },
  TFVAQ: { fullLabel: "TFVAQ -- Total Fair Value Assets" },
  TFVCEQ: {
    fullLabel: "TFVCEQ -- Total Fair Value Changes including Earnings",
  },
  TFVCEY: {
    fullLabel: "TFVCEY -- Total Fair Value Changes including Earnings",
  },
  TFVLQ: { fullLabel: "TFVLQ -- Total Fair Value Liabilities" },
  TIC: { fullLabel: "Ticker Symbol" },
  TIEQ: { fullLabel: "TIEQ -- Interest Expense - Total (Financial Services)" },
  TIEY: { fullLabel: "TIEY -- Interest Expense - Total (Financial Services)" },
  TIIQ: { fullLabel: "TIIQ -- Interest Income - Total (Financial Services)" },
  TIIY: { fullLabel: "TIIY -- Interest Income - Total (Financial Services)" },
  TSAFCY: { fullLabel: "TSAFCY -- Total Srcs of Funds (FOF)" },
  TSTKNQ: { fullLabel: "TSTKNQ -- Treasury Stock - Number of Common Shares" },
  TSTKQ: { fullLabel: "TSTKQ -- Treasury Stock - Total (All Capital)" },
  TXACHY: {
    fullLabel: "TXACHY -- Income Taxes - Accrued - Increase (Decrease)",
  },
  TXBCOFY: {
    fullLabel:
      "TXBCOFY -- Excess Tax Benefit of Stock Options - Cash Flow Financing",
  },
  TXBCOY: {
    fullLabel:
      "TXBCOY -- Excess Tax Benefit of Stock Options - Cash Flow Operating",
  },
  TXDBAQ: { fullLabel: "TXDBAQ -- Deferred Tax Asset - Long Term" },
  TXDBCAQ: { fullLabel: "TXDBCAQ -- Current Deferred Tax Asset" },
  TXDBCLQ: { fullLabel: "TXDBCLQ -- Current Deferred Tax Liability" },
  TXDBQ: { fullLabel: "TXDBQ -- Deferred Taxes - Balance Sheet" },
  TXDCY: { fullLabel: "TXDCY -- Deferred Taxes (Statement of Cash Flows)" },
  TXDIQ: { fullLabel: "TXDIQ -- Income Taxes - Deferred" },
  TXDITCQ: { fullLabel: "TXDITCQ -- Deferred Taxes and Investment Tax Credit" },
  TXDIY: { fullLabel: "TXDIY -- Income Taxes - Deferred" },
  TXPDY: { fullLabel: "TXPDY -- Income Taxes Paid" },
  TXPQ: { fullLabel: "TXPQ -- Income Taxes Payable" },
  TXTQ: { fullLabel: "TXTQ -- Income Taxes - Total" },
  TXTY: { fullLabel: "TXTY -- Income Taxes - Total" },
  TXWQ: { fullLabel: "TXWQ -- Excise Taxes" },
  TXWY: { fullLabel: "TXWY -- Excise Taxes" },
  UACOQ: { fullLabel: "UACOQ -- Current Assets - Other - Utility" },
  UAOLOCHY: {
    fullLabel:
      "UAOLOCHY -- Other Assets and Liabilities - Net Change (Statement of Cash Flows)",
  },
  UAOQ: { fullLabel: "UAOQ -- Other Assets - Utility" },
  UAPTQ: { fullLabel: "UAPTQ -- Accounts Payable - Utility" },
  UCAPSQ: { fullLabel: "UCAPSQ -- Paid In Capital - Other - Utility" },
  UCCONSQ: { fullLabel: "UCCONSQ -- Contributions In Aid Of Construction" },
  UCEQQ: { fullLabel: "UCEQQ -- Common Equity - Total - Utility" },
  UDDQ: { fullLabel: "UDDQ -- Debt (Debentures) - Utility" },
  UDFCCY: {
    fullLabel:
      "UDFCCY -- Deferred Fuel - Increase (Decrease) (Statement of Cash Flows)",
  },
  UDMBQ: { fullLabel: "UDMBQ -- Debt (Mortgage Bonds)" },
  UDOLTQ: { fullLabel: "UDOLTQ -- Debt (Other Long-Term)" },
  UDPCOQ: { fullLabel: "UDPCOQ -- Debt (Pollution Control Obligations)" },
  UDVPQ: { fullLabel: "UDVPQ -- Preferred Dividend Requirements" },
  UDVPY: { fullLabel: "UDVPY -- Preferred Dividend Requirements - Utility" },
  UFRETSDY: { fullLabel: "UFRETSDY -- Tot Funds Ret ofSec&STD (FOF)" },
  UGIQ: { fullLabel: "UGIQ -- Gross Income (Income Before Interest Charges)" },
  UGIY: {
    fullLabel:
      "UGIY -- Gross Income (Income Before Interest Charges) - Utility",
  },
  UINVQ: { fullLabel: "UINVQ -- Inventories" },
  ULCOQ: { fullLabel: "ULCOQ -- Current Liabilities - Other" },
  UNIAMIQ: {
    fullLabel:
      "UNIAMIQ -- Net Income before Extraordinary Items After Noncontrolling Interest",
  },
  UNIAMIY: {
    fullLabel:
      "UNIAMIY -- Net Income before Extraordinary Items After Noncontrolling Interest - Utili",
  },
  UNOPINCQ: { fullLabel: "UNOPINCQ -- Nonoperating Income (Net) - Other" },
  UNOPINCY: {
    fullLabel: "UNOPINCY -- Nonoperating Income (Net) - Other - Utility",
  },
  UNWCCY: { fullLabel: "UNWCCY -- Inc(Dec)Working Cap (FOF)" },
  UOISY: { fullLabel: "UOISY -- Other Internal Sources - Net (Cash Flow)" },
  UOPIQ: { fullLabel: "UOPIQ -- Operating Income - Total - Utility" },
  UPDQ: { fullLabel: "UPDQ -- Update Code" },
  UPDVPQ: { fullLabel: "UPDVPQ -- Preference Dividend Requirements - Utility" },
  UPDVPY: { fullLabel: "UPDVPY -- Preference Dividend Requirements - Utility" },
  UPMCSTKQ: { fullLabel: "UPMCSTKQ -- Premium On Common Stock - Utility" },
  UPMPFQ: { fullLabel: "UPMPFQ -- Premium On Preferred Stock - Utility" },
  UPMPFSQ: { fullLabel: "UPMPFSQ -- Premium On Preference Stock - Utility" },
  UPMSUBPQ: {
    fullLabel: "UPMSUBPQ -- Premium On Subsidiary Preferred Stock - Utility",
  },
  UPSTKCQ: {
    fullLabel: "UPSTKCQ -- Preference Stock At Carrying Value - Utility",
  },
  UPSTKQ: {
    fullLabel: "UPSTKQ -- Preferred Stock At Carrying Value - Utility",
  },
  UPTACY: {
    fullLabel: "UPTACY -- Utility Plant - Gross Additions (Cash Flow)",
  },
  URECTQ: { fullLabel: "URECTQ -- Receivables (Net) - Utility" },
  USPIQ: { fullLabel: "USPIQ -- Special Items - Utility" },
  USPIY: { fullLabel: "USPIY -- Special Items - Utility" },
  USTDNCY: { fullLabel: "USTDNCY -- Net Decr in ST Debt (FOF)" },
  USUBDVPQ: {
    fullLabel: "USUBDVPQ -- Subsidiary Preferred Dividends - Utility",
  },
  USUBDVPY: {
    fullLabel: "USUBDVPY -- Subsidiary Preferred Dividends - Utility",
  },
  USUBPCVQ: {
    fullLabel:
      "USUBPCVQ -- Subsidiary Preferred Stock At Carrying Value - Utility",
  },
  UTEMQ: { fullLabel: "UTEMQ -- Maintenance Expense - Total" },
  UTFDOCY: { fullLabel: "UTFDOCY -- Total Funds From Ops (FOF)" },
  UTFOSCY: { fullLabel: "UTFOSCY -- Tot Funds Frm Outside Sources (FOF)" },
  UTMEY: { fullLabel: "UTMEY -- Maintenance Expense - Total" },
  UWKCAPCY: { fullLabel: "UWKCAPCY -- Dec(Inc) in Working Capital (FOF)" },
  WCAPCHY: { fullLabel: "WCAPCHY -- Working Capital Changes - Total" },
  WCAPCY: {
    fullLabel: "WCAPCY -- Working Capital Change - Other - Increase/(Decrease)",
  },
  WCAPQ: { fullLabel: "WCAPQ -- Working Capital (Balance Sheet)" },
  WDAQ: { fullLabel: "WDAQ -- Writedowns After-tax" },
  WDAY: { fullLabel: "WDAY -- Writedowns After-tax" },
  WDDQ: { fullLabel: "WDDQ -- Writedowns Diluted EPS Effect" },
  WDDY: { fullLabel: "WDDY -- Writedowns Diluted EPS Effect" },
  WDEPSQ: { fullLabel: "WDEPSQ -- Writedowns Basic EPS Effect" },
  WDEPSY: { fullLabel: "WDEPSY -- Writedowns Basic EPS Effect" },
  WDPQ: { fullLabel: "WDPQ -- Writedowns Pretax" },
  WDPY: { fullLabel: "WDPY -- Writedowns Pretax" },
  WEBURL: { fullLabel: "WEBURL -- Web URL" },
  XACCQ: { fullLabel: "XACCQ -- Accrued Expenses" },
  XIDOCY: {
    fullLabel:
      "XIDOCY -- Extraordinary Items and Discontinued Operations (Statement of Cash Flows)",
  },
  XIDOQ: {
    fullLabel: "XIDOQ -- Extraordinary Items and Discontinued Operations",
  },
  XIDOY: {
    fullLabel: "XIDOY -- Extraordinary Items and Discontinued Operations",
  },
  XINTQ: { fullLabel: "XINTQ -- Interest and Related Expense- Total" },
  XINTY: { fullLabel: "XINTY -- Interest and Related Expense- Total" },
  XIQ: { fullLabel: "XIQ -- Extraordinary Items" },
  XIY: { fullLabel: "XIY -- Extraordinary Items" },
  XOPRQ: { fullLabel: "XOPRQ -- Operating Expense- Total" },
  XOPRY: { fullLabel: "XOPRY -- Operating Expense- Total" },
  XOPT12: { fullLabel: "XOPT12 -- Implied Option Expense - 12mm" },
  XOPTD12: { fullLabel: "XOPTD12 -- Implied Option EPS Diluted 12MM" },
  XOPTD12P: {
    fullLabel: "XOPTD12P -- Implied Option 12MM EPS Diluted Preliminary",
  },
  XOPTDQ: { fullLabel: "XOPTDQ -- Implied Option EPS Diluted" },
  XOPTDQP: { fullLabel: "XOPTDQP -- Implied Option EPS Diluted Preliminary" },
  XOPTDQPY: { fullLabel: "XOPTDQPY -- Implied Option EPS Diluted Preliminary" },
  XOPTDY: { fullLabel: "XOPTDY -- Implied Option EPS Diluted" },
  XOPTEPS12: { fullLabel: "XOPTEPS12 -- Implied Option EPS Basic 12MM" },
  XOPTEPSP12: {
    fullLabel: "XOPTEPSP12 -- Implied Option 12MM EPS Basic Preliminary",
  },
  XOPTEPSQ: { fullLabel: "XOPTEPSQ -- Implied Option EPS Basic" },
  XOPTEPSQP: { fullLabel: "XOPTEPSQP -- Implied Option EPS Basic Preliminary" },
  XOPTEPSQPY: {
    fullLabel: "XOPTEPSQPY -- Implied Option EPS Basic Preliminary",
  },
  XOPTEPSY: { fullLabel: "XOPTEPSY -- Implied Option EPS Basic" },
  XOPTQ: { fullLabel: "XOPTQ -- Implied Option Expense" },
  XOPTQP: { fullLabel: "XOPTQP -- Implied Option Expense Preliminary" },
  XOPTQPY: { fullLabel: "XOPTQPY -- Implied Option Expense Preliminary" },
  XOPTY: { fullLabel: "XOPTY -- Implied Option Expense" },
  XRDQ: { fullLabel: "XRDQ -- Research and Development Expense" },
  XRDY: { fullLabel: "XRDY -- Research and Development Expense" },
  XSGAQ: { fullLabel: "XSGAQ -- Selling, General and Administrative Expenses" },
  XSGAY: { fullLabel: "XSGAY -- Selling, General and Administrative Expenses" },
};

export default CompanyFundamentals;
