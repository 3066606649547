import "./App.css";
import React from "react";
import { useState } from "react";
import { BrowserRouter, Route, Router, Link } from "react-router-dom";
import {
  Nav,
  Navbar,
  Form,
  Button,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";

import DebtSecurity from "./DebtSecurity";
import EBP from "./EBP";
import CompanyFundamentals from "./CompanyFundamentals";
import Movers from "./Movers";

import { LinkContainer } from "react-router-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [debtTicker, setDebtTicker] = useState("MyDebtTicker");
  const [newTicker, setNewTicker] = useState("");

  const handleChange = ({ target }) => {
    setNewTicker(target.value);
  };

  const handleClick = (event) => {
    event.preventDefault();
    setDebtTicker(newTicker);
    setNewTicker("");
    //getData().then((res) => setPriceData(res));
  };

  const getData = async () => {
    const apiRes = await fetch(
      `https://api.research.lawson.net.au/api/bond-prices?bond_id=${newTicker}`
    );
    const resJSON = await apiRes.json();
    return resJSON;
  };

  return (
    <BrowserRouter>
      <div className="App bg-dark text-white">
        <Navbar bg="dark" variant="dark" className="justify-content-between">
          <Navbar.Brand>Research App</Navbar.Brand>
          <Navbar.Collapse>
            <LinkContainer to="/Home">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/Movers">
              <Nav.Link>Movers</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/CompanyFundamentals">
              <Nav.Link>Fundamentals</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/DebtSecurity">
              <Nav.Link>Debt</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/Options">
              <Nav.Link>Options</Nav.Link>
            </LinkContainer>
          </Navbar.Collapse>
          <Form inline>
            <FormControl
              type="text"
              placeholder="Search"
              className=" mr-sm-2"
              value={newTicker}
              onChange={handleChange}
            />

            <Button onClick={handleClick}>Submit</Button>
          </Form>
        </Navbar>
        <Row>
          <Col lg="10">
            <Route path="/DebtSecurity/:cusip" component={DebtSecurity} />
            <Route path="/DebtSecurity/" component={DebtSecurity} />
            <Route
              path="/CompanyFundamentals/:ticker"
              component={CompanyFundamentals}
            />
            <Route path="/Movers" component={Movers} />
            <Route path="/EBP/:cusip" component={EBP} />
          </Col>
        </Row>
      </div>
    </BrowserRouter>
  );
}

export default App;
