/** @jsx jsx */
//import React, { useState } from "react";
import styled from "@emotion/styled";
import { /*css,*/ jsx } from "@emotion/react";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Badge,
  Form,
} from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

const Movers = ({ match }) => {
  const [moversData, setMoversData] = useState({});
  const [marketCapMin, setMarketCapMin] = useState(250000000);
  const [marketCapMax, setMarketCapMax] = useState(10000000000);
  const [isLoading, setIsLoading] = useState(true);

  document.title = "Movers";

  // Use Effect to caputure when company ticker changes
  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  useEffect(() => {}, [marketCapMin, marketCapMax]);

  const getData = async () => {
    const apiRes = await fetch(
      `https://api.research.lawson.net.au/api/stock-movers`
    );
    const resJSON = await apiRes.json();
    setMoversData(resJSON);
    setIsLoading(false);
    //return resJSON;
  };

  if (isLoading === false) {
    return (
      <Container fluid className="bg-dark text-white">
        <h1>Movers</h1>
        <Form>
          <Col>
            <Form.Group controlId="formMarketCapMin">
              <Form.Label>Market Cap. Min</Form.Label>
              <Form.Control
                value={marketCapMin}
                onChange={({ target }) => {
                  setMarketCapMin(target.value);
                }}
                type="text"
                placeholder="Market Cap. Min"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formMarketCapMax">
              <Form.Label>Market Cap. Max</Form.Label>
              <Form.Control
                value={marketCapMax}
                onChange={({ target }) => {
                  setMarketCapMax(target.value);
                }}
                type="text"
                placeholder="Market Cap. Max"
              />
            </Form.Group>
          </Col>
        </Form>

        <Table variant="dark" size="sm">
          <thead>
            <tr>
              <td>Ticker</td>
              <td>Name</td>
              <td className={"text-right text-nowrap"}>Market Cap</td>
              <td className={"text-right text-nowrap"}>Share Outstanding</td>
              <td className={"text-right text-nowrap"}>Close</td>
              <td className={"text-right text-nowrap"}>Prev Close</td>
              <td className={"text-right text-nowrap"}>Return</td>
              <td className={"text-right text-nowrap"}>Low 52</td>
              <td className={"text-right text-nowrap"}>High 52</td>
              <td>Sub Industry</td>
            </tr>
          </thead>
          <tbody>
            {moversData
              .filter((row) => {
                return (
                  row["mk_cap"] >= marketCapMin && row["mk_cap"] <= marketCapMax
                );
              })
              .map((row) => (
                <tr>
                  <td>
                    <Link to={`/CompanyFundamentals/${row.tic}`}>
                      {row["tic"]}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/CompanyFundamentals/${row.tic}`}>
                      {row["conm"]}
                    </Link>
                  </td>
                  <td className={"text-right text-nowrap"}>
                    {Number(row["mk_cap"]).toLocaleString("en-US")}
                  </td>
                  <td className={"text-right text-nowrap"}>
                    {Number(row["cshoc"]).toLocaleString("en-US")}
                  </td>
                  <td className={"text-right text-nowrap"}>
                    {Number(row["prccd"]).toLocaleString("en-US")}
                  </td>
                  <td className={"text-right text-nowrap"}>
                    {Number(row["prccd_prev"]).toLocaleString("en-US")}
                  </td>
                  <td className={"text-right text-nowrap"}>
                    {Number(row["ret"]).toLocaleString(undefined, {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td className={"text-right text-nowrap"}>
                    {Number(row["low52"]).toLocaleString("en-US")} (
                    {Number(row["low52pct"]).toLocaleString(undefined, {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                    )
                  </td>
                  <td className={"text-right text-nowrap"}>
                    {Number(row["high52"]).toLocaleString("en-US")} (
                    {Number(row["high52pct"]).toLocaleString(undefined, {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                    )
                  </td>
                  <td>{row["gsubindname"]}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    );
  } else {
    return (
      <Container
        fluid
        className="bg-dark text-white"
        className="bg-dark text-white"
      >
        <div style={{ width: "500px", borderwidth: "3px" }}>
          <h1 style={{ display: "inline-block", paddingRight: "10px" }}>
            Movers
          </h1>
          <Spinner
            animation="border"
            role="status"
            style={{ display: "inline-block" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }
};

export default Movers;
